import $ from "jquery";

function navbar() {
	const navItems = document.querySelectorAll(".nav-item");
	function handleLinkClick(e) {
		const activeElem = document.querySelector(".nav-item.active");

		activeElem.classList.remove("active");
		e.target.parentElement.classList.add("active");
	}

	navItems.forEach((item) => {
		item.addEventListener("click", handleLinkClick);
	});

	// close nav if clicked on items and document
	$(document).click(function(event) {
		const click = $(event.target);
		const _open = $(".navbar-collapse").hasClass("show");
		if (_open === true && !click.hasClass("navbar-toggler")) {
			$(".navbar-toggler").click();
		}
	});
}

export default navbar;
